<template>
  <default-layout>
    <div class="container contact--us-container">
      <page-loading :show="loading" />
      <banner :banner-text="bannerText" />
      <div class="row contact--us-wrapper">
        <div class="col-12 col-md-4 left--col">
          <div class="title--text">PT. Real Estate Teknologi</div>
          <div class="address">
            Prosperity Tower 8th Floor<br />
            District 8, SCBD Lot 28<br />
            JI. Jend. Sudirman Kav. 52-53<br />
            Jakarta Selatan 12190
          </div>
          <div class="row contact--row">
            <div class="col-auto contact--left-col">
              <img src="@/assets/img/icons/mail-2.svg" />
            </div>
            <div class="col contact--right-col">
              <a href="mailto:enquiry@rentfix.com">enquiry@rentfix.com </a>
            </div>
          </div>
          <div class="row contact--row">
            <div class="col-auto contact--left-col">
              <img src="@/assets/img/icons/phone.svg" />
            </div>
            <div class="col contact--right-col">
              <a :href="`tel:+${$t('general.phone1')}`">{{ $t('general.phone1Text') }}</a>
              /
              <a :href="`tel:+${$t('general.phone2')}`">{{ $t('general.phone2Text') }}</a>
            </div>
          </div>
          <div class="row contact--row">
            <div class="col-auto contact--left-col">
              <img src="@/assets/img/icons/whatsapp-2.svg" />
            </div>
            <div class="col contact--right-col">
              <a
                :href="`https://api.whatsapp.com/send?phone=${$t('general.rentfixNumber')}`"
                target="_blank"
              >
                0811 9954 981
              </a>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6 right--col">
          <form @submit.prevent="submit">
            <div class="input--wrapper">
              <div class="form--group" :class="{ 'has-error': validation.hasError('name') }">
                <div class="col-4 left--col">
                  <label for="name">
                    <span>{{ $t('loginRegister.fullname') }}</span>
                  </label>
                </div>
                <div class="col-8 right--col">
                  <input id="name" v-model="name" placeholder="Nama" class="basic--input" />
                  <span class="val-error" v-if="validation.hasError('name')">{{
                    validation.firstError('name')
                  }}</span>
                </div>
              </div>
              <div class="form--group" :class="{ 'has-error': validation.hasError('email') }">
                <div class="col-4 left--col">
                  <label for="email">
                    <span>{{ $t('loginRegister.email') }}</span>
                  </label>
                </div>
                <div class="col-8 right--col">
                  <input
                    type="email"
                    v-model="email"
                    id="email"
                    placeholder="Email"
                    class="basic--input"
                  />
                  <span class="val-error" v-if="validation.hasError('email')">{{
                    validation.firstError('email')
                  }}</span>
                </div>
              </div>
              <div class="form--group" :class="{ 'has-error': validation.hasError('message') }">
                <div class="col-4 left--col">
                  <label for="message">
                    <span>{{ $t('loginRegister.message') }}</span>
                  </label>
                </div>
                <div class="col-8 right--col">
                  <textarea
                    placeholder="Pesan"
                    id="message"
                    v-model="message"
                    class="basic--input"
                    type="text"
                    rows="4"
                  />
                  <span class="val-error" v-if="validation.hasError('message')">{{
                    validation.firstError('message')
                  }}</span>
                </div>
              </div>
            </div>
            <div class="form--group">
              <div class="col-4 left--col"></div>
              <div class="col-8 right--col">
                <client-only>
                  <vue-recaptcha
                    class="vue--recaptcha"
                    ref="captcha"
                    @verify="onVerify"
                    @expired="onExpired"
                    :sitekey="captchaSiteKey"
                  />
                </client-only>
                <span v-show="showRecaptchaAlert" class="val-error"
                  ><strong>The captcha is not valid</strong></span
                >
              </div>
            </div>
            <div class="main--btn">
              <button class="btn btn-primary" type="submit">
                {{ $t('loginRegister.send') }}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </default-layout>
</template>

<script>
import { mapState } from 'vuex';
import HelperMixin from '@/mixins/helpers';
const PageLoading = () => import('@/components/content-loading/page-loading');

import DefaultLayout from '@/layouts/default';
import Banner from '@/components/utils/page-banner';
const VueRecaptcha = () => import('vue-recaptcha');
import { Validator } from 'simple-vue-validator';

export default {
  name: 'contact-us',
  mixins: [HelperMixin],
  components: {
    DefaultLayout,
    VueRecaptcha,
    PageLoading,
    Banner,
  },
  async fetch({ store, route }) {
    const baseUrl = store.state.global.baseUrl;
    const title = 'Hubungi Rentfix.com - Platform Sewa Properti di Indonesia';
    const description =
      'Hubungi kami di Rentfix.com, situs yang membantu Anda melakukan proses sewa properti mulai dari rumah, apartemen, ruko, kantor, gudang, kios, booth, coworking space, dan ruang pertemuan di seluruh Indonesia.';
    const head = {
      title: title,
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: description,
        },
        {
          hid: 'og-description',
          name: 'og:description',
          content: description,
        },
      ],
      link: [
        {
          rel: 'canonical',
          href: baseUrl + route.fullPath,
          id: 'canonical',
        },
      ],
    };
    store.commit('head/SET_CONTENT', head);
  },
  data: () => ({
    bannerText: 'Hubungi Kami',
    name: '',
    email: '',
    message: '',
    recaptchaResponse: '',
    showRecaptchaAlert: false,
    recaptchaVerified: false,
    recaptchaExpired: false,
    loading: false,
  }),
  computed: {
    ...mapState({
      captchaSiteKey: (state) => state.global.captchaSiteKey,
      user: (state) => state.global.user,
      isLoggedIn: (state) => state.global.loggedIn,
    }),
    emailInput: {
      get() {
        return this.email;
      },
      set(val) {
        this.email = this.cleanEmail(val);
      },
    },
  },
  validators: {
    name: {
      validator(value) {
        return Validator.value(value)
          .required(this.$i18n.t('errors.loginRegister.fullName.required'))
          .maxLength(255, this.$i18n.t('errors.loginRegister.fullName.max', { maxChar: 255 }));
      },
    },
    email: {
      debounce: 500,
      validator: function (email_) {
        return Validator.value(email_)
          .required(this.$i18n.t('errors.loginRegister.email.required'))
          .email(this.$i18n.t('errors.loginRegister.email.invalid'))
          .maxLength(255, this.$i18n.t('errors.loginRegister.email.max', { maxChar: 255 }));
      },
    },
    message: {
      validator(value) {
        return Validator.value(value)
          .required(this.$i18n.t('errors.message.required'))
          .maxLength(5000, this.$i18n.t('errors.message.max', { maxChar: 5000 }));
      },
    },
  },

  mounted() {
    this.reset();
  },
  methods: {
    reset() {
      if (this.isLoggedIn) {
        this.name = this.user.fullname;
        this.email = this.user.email;
        this.message = '';
      } else {
        this.name = '';
        this.email = '';
        this.message = '';
      }
      // this.$refs.captcha.reset();
    },
    async submit() {
      try {
        this.loading = true;
        const isValid = await this.$validate();
        if (isValid && this.recaptchaVerified && !this.recaptchaExpired) {
          let requestData = {
            name: this.name,
            email: this.email,
            message: this.message,
            'g-recaptcha-response': this.recaptchaResponse,
          };
          let response = await this.$store.dispatch('global/contactEnquiry', requestData);
          if (response.type === 'success') {
            await this.$swal(
              this.$t('general.success'),
              this.$t('general.contactSwalMsg'),
              'success',
            );
            this.reset();
            this.$refs.captcha.reset();
          }
        } else {
          this.showRecaptchaAlert = true;
        }
        // eslint-disable-next-line no-empty
      } catch (_) {
      } finally {
        this.loading = false;
      }
    },
    onVerify: function (response) {
      if (response) {
        this.recaptchaResponse = response;
        this.recaptchaVerified = true;
        this.showRecaptchaAlert = false;
      }
    },
    onExpired: function () {
      this.recaptchaExpired = true;
    },
  },
};
</script>
